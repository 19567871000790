<template>
    <div style="" class="lg:p-5">

        <MyStepperComponent :current-step="currentStep" :steps="steps.map(label => $t(label))" />

        <div class="card">

            <div class="card-body lg:px-5">

                <div class="text-center h4 font-weight-light text-app mb-5">
                    {{ headerStep[currentStep] }}
                    <div v-if="totalTickets <= 0 && formSubmitted" class="text-danger text-small">
                        Vous n'avez selectionner aucun ticket
                    </div>

                </div>


                <div class=" py-6 md:py-0" v-if="currentStep == 0">

                    <TicketInputComponent :value="cart[ticket.id]" v-on:ticket="onTicketChanged" :key="ticket.id"
                        v-for="ticket in tickets" :ticket="ticket" />

                    <div class="flex my-3 mt-6" :class="currentStep > 0 ? 'justify-between' : 'justify-end'">
                        <button v-if="currentStep > 0" @click="currentStep -= 1"
                            class="bg-primary h3 px-5 py-3 text-black font-bold">Precédent</button>

                        <button :disabled="isNextDisabled" :class="{ 'active': !isNextDisabled }" @click="onNext"
                            class="bg-primary h3 px-5 py-3 text-black font-bold">{{ $t('app.suivant') }}</button>
                    </div>
                </div>


                <div class=" py-6 md:py-0" v-if="currentStep == 1">

                    <AuthForm @go-back="currentStep -= 1" :current-user="user" @continue-with-user="goToPaiement" />

                </div>

                <div class="px-4 py-6 md:py-0" v-if="currentStep == 2">


                    <div class="mt-5 text-2xl text-center mt-3 mb-2">{{ $t('app.recapitulatif_de_votre_commande') }}</div>
                    <div class="text-center h4 mb-3 text-red-700 text-sm font-semibold">
                        <span class="">NB : </span>{{ $t('app.une_fois_votre_achat_validé___') }}</div>
                    <div class="flex justify-between">

                        <div class="text-sm font-light">
                            Pass
                        </div>

                        <div class="text-sm font-light">
                            {{ $t('app.tarif') }}
                        </div>

                        <div class="text-sm font-light">
                            {{ $t('app.quantite') }}
                        </div>

                    </div>

                    <hr>



                    <div :set="ticketModel = getTicket(ticketItem[0])" v-for="ticketItem in Object.entries(cart)"
                        class="flex justify-between">

                        <div class="text-sm ">
                            {{ ticketModel.label }}
                        </div>

                        <div class="text-sm">
                            {{ formatNumber(ticketModel.price) }} FCFA
                        </div>

                        <div class="text-sm font-semibold">
                            {{ ticketItem[1] }} ticket(s)
                        </div>

                    </div>


                    <div v-if="passKdo" class="mt-4 flex justify-between">
                        <div class="">
                            <div class="font-bold text-xl">Pass KDO</div>
                            <button @click="passKdo = undefined"
                                class="text-sm bg-red-100 hover:bg-red-400 hover:text-white transition duration-700 rounded-md p-1 px-4 font-semibold">RETIRER</button>
                        </div>
                        <div class="">
                            <div class="text-lg font-semibold">Montant disponible</div>
                            <div class="text-2xl text-primary font-bold">{{ passKdo.amount_left }} Fr</div>
                        </div>
                    </div>

                    <div class="text-center font-light mt-4 text-lg mb-1">TOTAL: {{ total }} FCFA</div>


                    <div class="text-center text-2xl font-semibold">{{ $t('app.total_a_payer') }}: {{ totalToBuy }} FCFA</div>



                    <div v-if="passKdo == undefined" class="flex justify-center flex-col md:flex-row items-center mt-12">
                        <button @click="newMakePayment"
                            class="bg-primary hover:bg-yellow-600 min-w-[150px] text-xl mx-auto transition duration-500 text-black p-2 font-semibold">{{ $t('app.payer') }}</button>
                        <span>Ou</span>
                        <button @click="tryUsePassKDO"
                            class="bg-black hover:bg-stone-800 min-w-[150px] text-xl mx-auto transition duration-500 text-white p-2 font-semibold">
                            {{ $t('app.utiliser_mon_pass_kdo') }}
                        </button>
                    </div>
                    <div v-else>
                        <button @click="newMakePayment"
                            class="bg-primary flex hover:bg-yellow-600 min-w-[150px] text-xl mx-auto transition duration-500 text-black p-2 font-semibold">
                            PAYER AVEC MON PASS KDO
                            <svg v-if="false" aria-hidden="true"
                            class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor" />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill" />
                        </svg>
                        </button>
                    </div>
                </div>


                <div v-if="currentStep == 3 && !!paymentResult">
                    <div class="h3 text-center" style="color: #00a249"> Félicitations ! Paiement éffectué avec succès</div>

                    <div class="text-center">
                        Consulter votre boite email ou votre compte whatsapp pour télécharger votre ticket ou cliquer sur
                        le(s) bouton(s) «Télécharger» ci-dessous.
                    </div>
                    <PaymentResultComponent :payment-result="paymentResult" />
                </div>


            </div>

        </div>
        <widget-container-modal />

    </div>
</template>

<script>

import { openModal, pushModal, closeModal, popModal } from "jenesius-vue-modal"
import { container } from "jenesius-vue-modal";

import StepperComponent from './StepperComponent.vue';
import TicketInputComponent from './TicketInputComponent.vue';
import PaymentResultComponent from './PaymentResultComponent.vue';
import MyStepperComponent from './MyStepperComponent.vue'
import AuthForm from '../auth/AuthForm.vue'
import PassKDOModal from './PassKDOModal.vue'

let paymentString = "app.paiement";
let coordonneString = "app.coordonnée";

console.log('trans paiement', paymentString, coordonneString)


import { object, string } from 'yup';
let userSchema = object({
    nom: string().required('Champ requis'),
    prenoms: string().required('Champ requis'),
    email: string().required('Champ requis').matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Vous devez saisir une adresse email valide'),
    phone: string().required('Champ requis').matches(/^[0-9]{10}$/, 'Vous devez saisir un numéro valide sans le préfixe'),
});

export default {
    components: {
        StepperComponent, TicketInputComponent, PaymentResultComponent, MyStepperComponent, AuthForm,
        WidgetContainerModal: container
    },
    props: {
        event: {},
        tickets: {
            default() {
                return [
                    {
                        id: 1,
                        label: "Place débout",
                        price: 10000
                    },
                    {
                        id: 2,
                        label: "Place assise",
                        price: 20000
                    }
                ];
            }
        }
    },
    data() {
        return {
            steps: [
                'Pass', this.$t(coordonneString), this.$t(paymentString)
            ],
            headerStep: [
                // 'Choissisez votre pass',
                // 'Merci de bien remplir les informations demandées. Vous recevrez un e-mail et/ou un message WhatsApp contenant votre ticket. Vous devrez le présenter au contrôle le jour du concert.',
                // 'Payez votre ticket'
            ],

            currentStep: 0,
            nom: undefined,
            prenoms: undefined,
            email: undefined,
            phone: undefined,
            cart: {
            },
            user: undefined,
            paymentResult: undefined,
            isBuying: false,
            formErrors: {

            },
            formSubmitted: false,
            passKdo: undefined
        };
    },
    methods: {
        formatNumber,
        async tryUsePassKDO() {
            const modal = await openModal(PassKDOModal);
            modal.on('pass-kdo', (pass) => {
                this.passKdo = pass;
                closeModal()
            })
        },
        goToPaiement(user) {
            this.user = user;
            this.onNext();
        },
        resetForm() {
            this.currentStep = 0;
            this.paymentResult = undefined;
            this.cart = {};
            this.isBuying = false;
            this.nom = "";
            this.prenoms = "";
            this.email = "";
            this.phone = "";
        },
        onNext() {
            this.formSubmitted = true
            if (this.currentStep == 0) {
                this.goToStep1();
            } else if (this.currentStep == 1) {
                this.goToStep2();
            } else {
                this.newMakePayment();
            }
        },
        getTicket(ticketId) {
            //console.log("tickets id", ticketId);
            const ticket = this.tickets.find(ticket => ticket.id == ticketId);

            //console.log("ticket", ticket);

            return ticket;
        },
        onTicketChanged({ ticket, quantity }) {
            console.log('Cart on ticket changed')
            this.cart = {
                ...this.cart,
                [ticket.id]: quantity
            }
        },
        goBack() {
            this.currentStep -= 1;
        },
        goToStep1() {
            if (this.totalTickets > 0) {
                this.currentStep += 1;
            }
        },
        async goToStep2() {

            try {

                // const result = userSchema.validateSync({
                //     nom: this.nom,
                //     prenoms: this.prenoms,
                //     email: this.email,
                //     phone: this.phone
                // }, {
                //     abortEarly: false
                // });

                this.currentStep += 1

            } catch (e) {
                //console.log({ ...e });
                let errors = Object.fromEntries(e.inner.map(
                    item => ([item.path, item.errors])
                ))
                this.formErrors = errors
                return;
            } finally {
                //console.log('Finnaly do ')
            }




        },
        async getPrepayment() {

        console.log("base url", BASE_URL);

            const prepayment = (await axios.post(`${BASE_URL}/api/prepayments`, {
                buyer_name: this.user.name,
                buyer_email: this.user.email,
                buyer_phone: this.user.phone,
                tickets: Object.entries(this.cart).map(([ticket, quantity]) => ({ ticket, quantity })),
                pass_kdo: this.passKdo?.unique_code
            })).data;

            console.log(prepayment);

            return prepayment;
        },

        async loadTicketBuy(prepaymentId) {

            try {
                //console.log('-----LOADING BUYED TICKET');

                this.isLoadingTicket = true;
                const ticketBuy = (await axios.get(`${BASE_URL}/api/checkout/${prepaymentId}`)).data
                this.paymentResult = ticketBuy;
                //console.log('-----LOADING TICKET DONE', ticketBuy);

            } catch (e) {
                //console.log(e)
            } finally {
                this.isLoadingTicket = false;
            }

        },

        onPaiementDone(prepaymentId) {
            this.loadTicketBuy(prepaymentId);
            this.currentStep = 3;
        },

        async makeFullPaymentWithPassKdo() {

            const result = (await axios.post(`${BASE_URL}/api/events/pay-with-passkdo`, {
                buyer_name: this.user.name,
                buyer_email: this.user.email,
                buyer_phone: this.user.phone,
                tickets: Object.entries(this.cart).map(([ticket, quantity]) => ({ ticket, quantity })),
                pass_kdo: this.passKdo.unique_code
            })).data;

            window.location = result.return_url;

        },

        async newMakePayment() {

            try {

                console.log("Debut du paiement")

                this.isBuying = true;

                if (this.totalToBuy == 0) {

                    await this.makeFullPaymentWithPassKdo();

                } else {

                    const prepaymentUrl = await this.getPrepayment();

                    // const paymentConfig = {

                    //     apikey: '15907760966315f78156bb92.89598914',
                    //     site_id: '164991',
                    //     mode: 'PRODUCTION',
                    //     notify_url: `${BASE_URL}/api/cinet-pay-notify-url/${prepayment.id}`,
                    //     transaction_id: prepayment.code,
                    //     amount: prepayment.amount,
                    //     currency: 'XOF',
                    //     channels: 'ALL',
                    //     description: 'Paiement de ticket',
                    //     //Fournir ces variables obligatoirement pour le paiements par carte bancaire
                    //     customer_city: "Abidjan",// La ville du client
                    //     customer_name: prepayment.payload.buyer_name,//Le nom du client
                    //     customer_surname: '',//Le prenom du client
                    //     customer_email: prepayment.payload.buyer_email,//l'email du client
                    //     customer_phone_number: prepayment.payload.buyer_phone,//l'email du client
                    //     customer_country: "CI",// le code ISO du pays
                    //     customer_state: "CI",// le code ISO l'état
                    //     customer_zip_code: "06510", // code postal

                    //     "return_url": prepayment.payment_status_url,
                    //     "lang": "FR",
                    // }

                    //console.log('payment config', paymentConfig);

                    // var data = JSON.stringify(paymentConfig);

                    // var config = {
                    //     method: 'post',
                    //     url: 'https://api-checkout.cinetpay.com/v2/payment',
                    //     headers: {
                    //         'Content-Type': 'application/json'
                    //     },
                    //     data: data
                    // };

                    window.location = prepaymentUrl
                }


            } catch (e) {
                this.isBuying = false;
                console.log(e)
                alert('An error happened: ' + e.message);
            }

        },

        async makePayment() {

            const prepayment = await this.getPrepayment();
            //console.log('-----GET PREPAYMENT DONE');
            let config = {
                apikey: '15907760966315f78156bb92.89598914',
                site_id: '164991',
                mode: 'PRODUCTION',
                notify_url: `${BASE_URL}/api/cinet-pay-notify-url/${prepayment.id}`
            };
            CinetPay.setConfig(config);

            //console.log("Cinetpay config", config);

            CinetPay.getCheckout({
                transaction_id: prepayment.code,
                amount: prepayment.amount,
                currency: 'XOF',
                channels: 'ALL',
                description: 'Paiement de ticket du concert: Concert GADJI Celi «The King is back»',
                //Fournir ces variables obligatoirement pour le paiements par carte bancaire
                customer_city: "Abidjan",// La ville du client
                customer_name: prepayment.payload.buyer_name,//Le nom du client
                customer_surname: '',//Le prenom du client
                customer_email: prepayment.payload.buyer_email,//l'email du client
                customer_phone_number: prepayment.payload.buyer_phone,//l'email du client
                customer_country: "CI",// le code ISO du pays
                customer_state: "CI",// le code ISO l'état
                customer_zip_code: "06510", // code postal
            });

            CinetPay.waitResponse((data) => {
                // En cas d'échec
                if (data.status == "REFUSED") {
                    if (alert("Votre paiement a échoué")) {
                        return;
                    }
                }
                // En cas de succès
                else if (data.status == "ACCEPTED") {
                    alert("Votre paiement a été effectué avec succès")
                    this.onPaiementDone(prepayment.id);
                    return
                }
            });

        }
    },
    computed: {
        totalTickets() {
            //console.log(this.cart);
            return Object.entries(this.cart).reduce(
                (accumulator, [key, value]) => {
                    return accumulator + value;
                }, 0
            ) > 0
        },
        total() {
            //console.log('Cart', this.cart);
            return Object.entries(this.cart).reduce(
                (accumulator, [key, value]) => {
                    //console.log('ticket id', key, value);
                    return accumulator + (value * this.getTicket(key).price);
                }, 0
            )
        },
        errorsEmpty() {
            return Object.entries(this.formErrors).length == 0;
        },
        isNextDisabled() {
            return false;
            if (this.currentStep == 0) {
                if (this.total > 0)
                    return false;
                return true;
            } else if (this.currentStep == 1) {
                if (this.nom?.length > 0 && this.prenoms?.length > 0 && this.phone?.length > 0 && this.email?.length > 0)
                    return false;
                return true;
            } else if (this.currentStep == 2) {
                return false;
            }
        },
        totalToBuy() {
            if (this.passKdo != undefined) {
                if (this.passKdo.amount_left >= this.total)
                    return 0;
                else
                    return this.total - this.passKdo.amount_left;
            }

            return this.total;
        }

    }
}
</script>

<style>
.step-btn {
    border-radius: 50px;
    background-color: #969ca0;
    border: none;
    color: white !important;
    transition: all 0.2s;
}

.step-btn.active {
    border-radius: 50px;
    background-color: #05121e;
    border: none;
    color: white !important;
    transition: all 0.2s;
}

.step-btn.active:hover {
    background-color: #101b257eb3;
}

.step-btn.back-btn:hover {
    background-color: #05121e;
}



.step-input {
    background-color: #efefeb;
    display: block;
    padding: 24px 12px;
    width: 100%;
    border: none;
    color: #505452;
}


.outer-circle {
    width: 5rem;
    height: 5rem;
    background-color: #fff;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
}

.inner-circle {
    border-radius: inherit;
    background-image: conic-gradient(#fff, rgba(80, 200, 120, 0.3), rgb(80, 200, 120));
    position: absolute;
    z-index: -1;
    margin: auto;
    top: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem;
    right: -0.8rem;
    animation: circle-anim 0.6s linear infinite;
}

@keyframes circle-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>

<style>
.sdk {
    display: block;
    position: absolute;
    background-position: center;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
